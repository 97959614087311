import styled from "styled-components"
import Typography from "@/styled/Typography"
import { Container, Row } from "@/styled/Grid"
import Col from "@/styled/Grid/Col"
import Image from "next/image"

const Background = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`

const ImageWrapper = styled.a`
  position: relative;
  width: 100px;
  height: auto;
  img {
    position: relative !important;
    max-width: 100px;
  }
`

const Item = styled(Col)`
  margin-bottom: 1rem;
`

export default function Partners() {
  return (
    <Background>
      <Container>
        <Typography variant="title2" $weight="medium" className="mb-2" center>
          Colaboradores
        </Typography>
        <Typography className="mb-5" center>
          Empresas colaboradoras de Physio WOW
        </Typography>
        <Row style={{ justifyContent: "center", textAlign: "center" }}>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://www.fisioterapeutes.cat/" target="_blank" rel="nofollow noopener">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/fisioterapeutes-de-catalunya"
                alt="Col·legi de fisioterapeutes de catalunya"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://www.padelmirasol.cat/" target="_blank" rel="nofollow noopener">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/padel-mirasol"
                alt="Padel Mirasol"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://one16sports.com/" target="_blank" rel="nofollow noopener">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/one_sixteen"
                alt="onesixteen"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper
              href="https://www.anytimefitness.es/"
              target="_blank"
              rel="nofollow noopener"
              aria-label="anytime fitness">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/v1586724317/static/partners/anytime_fitness"
                alt="anytime fitness"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://www.bepadel.net/" target="_blank" rel="nofollow noopener" aria-label="bepadel">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/bepadel"
                alt="bepadel"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://fisiocrem.es/" target="_blank" rel="nofollow noopener" aria-label="fisiocrem">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/fisiocrem"
                alt="fisiocrem"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2} className="d-none d-md-block d-lg-none">
            <ImageWrapper
              href="https://xcentricpadel.com/"
              target="_blank"
              rel="nofollow noopener"
              aria-label="xcentric">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/xcentric"
                alt="xcentric"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://doctoralia.es/" target="_blank" rel="nofollow noopener">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/doctoralia"
                alt="doctoralia"
                fill
              />
            </ImageWrapper>
          </Item>
          <Item xs={4} md={3} lg={2}>
            <ImageWrapper href="https://magnetofields.com/" target="_blank" rel="nofollow noopener">
              <Image
                src="https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/partners/magnetofield-logo"
                alt="magnetofield"
                fill
              />
            </ImageWrapper>
          </Item>
        </Row>
      </Container>
    </Background>
  )
}
